.contacts-main-field__title h2{
    font-size: 17.3vw;
}
.contacts-main-field__title .main-field__small-text{
    margin: 0 75px 0 13%;
    h4{
        position: absolute;
        top: 13%;
        font-family: "Parangon_530C",sans-serif;
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}
.links-and-social{
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
}
.contacts-main-field__title .content__wrapper{
    margin: 0 75px 0 13%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-6vw);
}

.contacts-main-field__title ul{
    margin: 0;
    padding: 0;
    margin-bottom: 6.3%;
    li{
        list-style-type: none;
        a{
          font-family: "Parangon_530C", sans-serif;
          font-size: 2.34375rem;
          margin-bottom: 2.34375rem;
        }
    }
}
.social__wrapper{
    display: flex;
    div{
        margin-right: 4.6875rem;
        img{
            width: 80%;
        }
    }
}
#mapid{
    width: 56%;
    height: 340px;
}
@media screen and (max-width: 991px){
    .main-field__title .main-field__small-text{
        margin: 0 25px;
    }
    .contacts-main-field__title .content__wrapper{
        flex-direction: column;
        transform: translateY(0);
        width: unset;
        margin: 0;
    }
    .main-field__title .main-field__small-text{
        padding: 15% 0 0 0;
        margin-bottom: 37px;
    }
    .links-and-social{
        margin: 0 25px;
    }
    .contacts-main-field__title ul{
        margin-bottom: 0;
    }
    .contacts-main-field__title .main-field__small-text h4{
        position: relative;
    }
    .social__wrapper{
        margin: 42px 0;
    }
    #mapid{
        width: 100%;
        height: 200px;
    }
}
@media screen and (max-width: 425px){
    .contacts-main-field__title ul li a{
        font-size: 16px;
    }
    .contacts-main-field__title .main-field__small-text h4{
        font-size: 25px;
    }
    .contacts-main-field__title{
        margin-top: 15%;
    }
    .social__wrapper div{
        margin-right: 10px;
        width: 40px;
    }
}