body, html {
  min-width: 320px;
  font-size: 16px;
}

.main-page-body--color-white{
  background-color: white;
}


// Rewrite bootstrap
@media (min-width: 1830px) {
  .container {
    max-width: 1800px;
  }
}
@media screen and (max-width: 1680px){
  body,
  html{
    font-size: 15px;
  }
}
@media screen and (max-width: 1600px){
  body,
  html{
    font-size: 14px;
  }
}
@media screen and (max-width: 1440px){
  body,
  html{
    font-size: 13px;
  }
}
@media screen and (max-width: 1366px){
  body,
  html{
    font-size: 12px;
  }
}
