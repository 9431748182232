.main-field__title--clients .main-field__small-text .main-field__wrapper p{
  font-family: "Parangon_530C", sans-serif;
  font-size: 1.5625vw;
  margin-bottom: 0;
}
.main-field__wrapper--font-weight-normal p{
  font-family: "Parangon_330C", sans-serif !important; 
}
.main-field__wrapper{
  width: 130%;
  margin-bottom: 5%;
}

@media screen and (max-width: 991px){
  .main-field__title--clients .main-field__small-text .main-field__wrapper p{
    font-size: 25px;
  }
  .main-field .main-field__title--clients h2{
    font-size: 21.5vw !important;
    transform: translateX(-8px) !important;
  }
  .main-field__title--clients .main-field__small-text{
    padding-top: 15%;
    margin-left: 25px;
    width: unset;
    p{
      font-size: 16px;
      width: 100%;
    }
    .main-field__wrapper{
      width: unset
    }
  }
}
@media screen and (max-width:768px){
  .main-field__title--clients .main-field__small-text{
    padding-top: 15% !important;
    margin-left: 25px !important;
    width: unset !important;
  }
  .main-field__title--clients .main-field__small-text .main-field__wrapper p{
    font-size: 20px;
  }
  .main-field .main-field__title--clients .main-field__small-text h4 strong {
    font-size: 22px !important;
  }
}
@media screen and (max-width:425px){
  .main-field__title--clients .main-field__small-text .main-field__wrapper p{
    font-size: 16px;
  }
}


