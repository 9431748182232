a{
  color: black;
  text-decoration: none;
}
a:hover{
  color: black;
  text-decoration: none;
}
.main-body{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
