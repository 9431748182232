.about-us__first-block{
  display: flex;
  width: 75%;
  margin: 0 auto 4.55% auto;
}
.about-us__first-block .photo-wrapper,
.about-us__first-block .text-wrapper{
  width: 50%;
  display: flex;
}
.about-us__first-block .photo-wrapper{
  align-items: flex-end;
}

.about-us__first-block .photo-wrapper__img{
  width: 95.5056179775%;
  height: 82.5581395349%;
  background-color: #CDCACB;
  visibility: hidden;
}
.about-us__first-block .photo-wrapper__img img{
  width: 100%;
  height: auto;
}
.about-us__first-block .text-wrapper{
  display: flex;
  flex-direction: column; 
  transform: translateY(-1rem);
}
.about-us__first-block .text-wrapper p{
  margin-bottom: 0;
}
@media screen and (max-width: 991px){
  .about-us__first-block{
    flex-direction: column-reverse;
    .text-wrapper{
      transform: translateY(0);
      width: 100%;
    }
    .photo-wrapper{
      width: 100%;
    }
  }
}
@media screen and (max-width: 425px){
  .about-us__first-block .text-wrapper p{
    font-size: 16px;
  }
  .about-us__main-field .main-field__small-text h4{
    font-size: 23px;
  }
  .about-us__first-block{
    width: unset;
    margin: 0 25px;
  }
}