.clients__item {
  margin-bottom: 5%;
  transition: all 0.5s linear;
  width: 22.5%;
}
.clients__item .img__wrapper {
  width: 100%;
  text-align: center;
}
.clients__item .img__wrapper img {
  width: 100%;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.06);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.06);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.06);
}
.clients__item--opacity-half{
  opacity: 0.5;
  transition: all 0.5s linear;
}

