.choose-bar {
  margin-left: 14%;
  margin-bottom: 5%;
}
.choose-bar a {
  margin-right: 2%;
  font-size: 1.7rem;
  font-family: "Parangon_530C", sans-serif;
}

.choose-bar--color-red {
  color: red;
}
.choose-bar--color-red:hover {
  color: red;
}
@media screen and (max-width: 991px){
  .choose-bar a{
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 425px){
  .choose-bar{
    margin: 0 25px 14px 25px;
  }
  .choose-bar a{
    font-size: 21px;
  }
}
@media screen and (max-width: 375px){
  .choose-bar a{
    font-size: 18px;
  } 
}
@media screen and (max-width: 320px){
  .choose-bar{
    display: flex;
    justify-content: space-between;
  }
}
