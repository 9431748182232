.about-us__second-block{
    width: 75%;
    margin: 0 auto;
}
.about-us__second-block h3{
    font-family: 'Parangon_530C', sans-serif;
    font-size: 3.875rem;
    margin-bottom: 5.35%;
}
.services__wrapper{
    display: flex;
    justify-content: space-between;
}
.services{
    h6{
        color: red;
        font-family: 'Parangon_530C', sans-serif;
        font-size: 2.3125rem;
        margin-bottom: 2.5rem;
    }
    ul{
    margin: 0;
    padding: 0;
    li{
        list-style-type: none;
        font-family: 'Parangon_330C', sans-serif;
        font-size: 1.5625rem;
    }
}
}
@media screen and (max-width: 1024px){
    .services h6{
        font-size: 2rem;
    }
    .services ul li{
        font-size: 1.2rem; 
    }
}
@media screen and (max-width: 991px){
    .services__wrapper{
        flex-direction: column;
    }
    .services{
        margin-bottom: 35px;
    }
    .services:nth-child(odd){
        text-align: right;
    }
    .services h6{
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 425px){
    .about-us__second-block{
        margin: 0 25px;
        width: unset;
    }
    .about-us__second-block h3{
        font-size: 23px;
    }
    .services h6{
        font-size: 25px;
        margin-bottom: 22px;
    }
    .services ul li{
        font-size: 16px;
    }
}