.clients_container {
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 425px){
  .clients_container{
    width: unset;
    margin: 0 25px;
  }
}
