.success_main-field{
    h1{
        position: absolute;
        text-align: center;
        position: absolute;
        height: 0;
        width: 100%;
        text-transform: uppercase;
        color: #fff;
        font-family: "Parangon_530C",sans-serif;
        font-size: 17.4479vw;
        text-align: center;
        margin-top: 5%;
        margin-bottom: 0;
    }
    .text{
        position: relative;
        z-index: 3;
        padding-top: 7%;
        width: 50%;
        padding-left: 17vw;
        p{
            font-family: "Parangon_330C" ,sans-serif;
            font-size: 1.875rem;
        }
    }
    img{
        z-index: 4;
    }
}
@media screen and (max-width: 991px){
    .success_main-field h1{
        font-size: 24.4479vw;
    }
    .success_main-field .text{
        width: 70%;
    }
}
@media screen and (max-width: 425px){
    .success_main-field .text{
        padding-left: 25px;
        width: 90%;

    }
}