.drop-down{
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  z-index: 101;
}

.drop-down__top-branch,
.drop-down__middle-branch,
.drop-down__bottom-branch{
  position: relative;
  width: 50px;
  height: 5px;
  background-color: red;
  margin: 7px 0;
  transition: all .2s linear;
}
.drop-down__wrapper--position-top-left{
  position: absolute;
  right: 3vw;
  top: 55px;
  z-index: 101;
}
.drop-down__top-branch--active{
  transform: rotate(-45deg);
  top: 5px;
}
.drop-down__middle-branch--active{
  display: none;
}
.drop-down__bottom-branch--active{
  transform: rotate(45deg);
  bottom: 7px;
}
@media screen and (max-width: 991px){
  .drop-down__top-branch,
  .drop-down__middle-branch,
  .drop-down__bottom-branch{
    width: 35px;
    height: 5px;
    margin: 4px 0;
  }
  .drop-down__wrapper--position-top-left{
    right: 25px;
    top: 25px;
  }
  .drop-down__top-branch--active{
    top: 2px;
  }
}
