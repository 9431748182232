.about-us{
  width: 99vw;

}
.text--styled p{
  font-size: 1.5625rem;
  font-family: "Parangon_330C", sans-serif;
}


