.vacancie{
  width: 53%;
  margin-left: 14%;
  margin-bottom: 4%;
}
.vacancie h3 a{
  font-family: "Parangon_530C", sans-serif;
  font-size: 3.90625rem;
  margin: 0;
  font-weight: 700;
}
.vacancie h3 a:hover{
  color: red;
}
.vacancie p{
  font-family: "Parangon_330C", sans-serif;
  font-size: 1.875rem;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 1024px){
  .vacancie h3 a{
    font-size: 3rem;
  }
  .vacancie p{
    font-size: 1.2rem;
  }
  .breadcrumb li{
    font-size: 1.7rem;
  }
  .breadcrumb a{
    font-size: 1.7rem !important;
  }
}

@media screen and (max-width: 768px){
  .vacancie h3 a{
    font-size: 2rem;
  }
  .vacancie p{
    font-size: 16.67px;
  }
  .breadcrumb li{
    font-size: 1.3rem;
  }
  .breadcrumb a{
    font-size: 1.3rem !important;
  }
}
@media screen and (max-width: 425px){
  .main-field__small-text{
    margin:0 25px;
    width: unset;
  }
  .vacancie{
    margin:0 25px 32px 25px;
    width: unset;
    h3{
      font-size: 25px;
      margin-bottom: 15px;
    }
  }
}
