.about-us__main-field h2{
    font-size: 20.5vw;
}


@media screen and (max-width: 768px){
    .about-us__main-field .main-field__small-text{
        width: 75%;
        margin: 0 auto;
    }
}
@media screen and (max-width: 425px){
    .about-us__main-field .main-field__small-text{
        margin: 0 25px;
      }
}