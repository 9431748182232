.about-us__third-block{
    margin-bottom: 6.8%;
}
.about-us__third-block h2{
    color: white;
    text-transform: uppercase;
    font-size: 14.4479166667vw;
    font-family: "Parangon_530C", sans-serif;
    text-align: center;
    
}
.our-team{
    width: 75%;
    margin: 0 auto;
}
.our-team .text{
    display: flex;
    .text__description{
        width: 50%;
        p{
            font-size: 1.5625rem;
            font-family: 'Parangon_330C', sans-serif;
        }
    }
    h5{
        font-size: 3.875rem;
        font-family: 'Parangon_530C', sans-serif;
        transform: translate(1rem,-8rem);
        strong{
            font-weight: normal;
            color: red;
        }
    }
}
.about-us__third-block .line{
    background-color: red;
    width: 13%;
    margin-left: 12.5%;
    height: 0.65rem;
    transform: translateY(1.75rem);
}
.first-slider{
    height: 31.25vw;
    
}
.first-slider .person{
    transition: all 0.5s linear;
    height: auto;
    width: 13vw;
    img{
        width: 100%;
        height: auto;
        transition: all 0.5s linear;
    }
}
.first-slider .slick-track{
    height: 100%;
    display: flex;
    align-items: flex-end; 
}
.first-slider .slick-list{
    height: 100%;
    padding: 0 5.5% 0 0 !important;
}
.slick-list{
    min-height: 17vw;
}
.img-active{
    margin-right: 12vw;
}
.first-slider .person:nth-child(even){
    transform: translateY(-5rem)
}
.slider{
    position: relative;
}
  .buttons{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 23%;
      margin-left: 51.4%;
      position: absolute;
      bottom: 0;
      color: red;
      cursor: pointer;
      
  }
  .prev,
  .next{
      height: 50px;
  }
  .signature {
    width: 52%;
    position: absolute;
    top: 1.5%;
    .name,
    .work{
        font-size: 2rem;
        font-family: 'Parangon_530C', sans-serif;
        text-align: right;
    }
    .name{
        color: red;
    }
    
  }
@media screen and (max-width: 1440px){
    .buttons{
        bottom: -2%; 
    } 
}
@media screen and (max-width: 1024px){
    .buttons{
        bottom: -3.5%; 
    } 
}
@media screen and (max-width: 991px){
    .about-us__third-block h2{
        font-size: 19.247917vw;
        transform: translateX(-10px);
    }
    .our-team .text{
        flex-direction: column-reverse;
        transform: translate(0px, -9rem);
        h5{
            transform: translate(0, 0);
        }
    }
    .first-slider .person img{
        width: 70%;
        margin: 0 auto;
    }
    .our-team .text .text__description{
        width: 100%;
    }
    .about-us__third-block .line{
        display: none;
    }
    .first-slider .person{
        width: 250px;
    }
    .first-slider .person:nth-child(odd),
    .first-slider .person:nth-child(even){
        transform: translate(0)
    }
    .first-slider{
        height: 400px;
    }
    .first-slider .slick-list{
        padding: 0 !important;
    }
    .first-slider .slick-track{
        display: flex;
        align-items: center;
    }
    
    .buttons{
        width: 291px;
        margin-left: 50%;
        bottom: 0;
        z-index: 99;
        transform: translate(-50%, 12px);
    }
    .signature{
        width: 100%;
        position: absolute;
        bottom: 0;
        top: unset;
        transform: translateY(100%);
        .name,
        .work{
            text-align: center;
        }
        .name{
            margin-top: 10px;
        }
    }
}
@media screen and (max-width: 425px){
    .our-team{
        width: unset;
        margin: 0 25px;
    }
    .our-team .text{
        transform: translate(0,-4rem);  
    }
    .our-team .text h5{
        font-size: 25px;
    }
    .our-team .text .text__description p{
        font-size: 16px;
    }
}