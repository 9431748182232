.main-field{
  margin-bottom: 5%;
}
.main-field__title{
  display: flex;
  flex-direction: column;
  margin-top: 6%;
  width: 100%;
}
.main-field__small-logo{
  display: block;
  position: absolute;
  top: 50px;
  left: 2.8125vw;
  height: 9.11458333333vw;
  z-index: 3;
}

.main-field__title h2{
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Parangon_530C", sans-serif;
  font-weight: bold;
  font-size: 18.7vw;
  transform: translateX(-6px);
  position: absolute;
  width: 100%;
  text-align: center;
}
.main-field__title--clients h2{
  font-size: 21vw;
  transform: translateX(-6px);
}
.main-field__title  .main-field__small-text{
  margin-left: 14%;
  width: 53%;
  position: relative;
  padding-top: 15%;
  padding-bottom: 2%
}
.main-field__title--clients  .main-field__small-text{
  margin-left: 14%;
  width: 53%;
  position: relative;
  padding-top: 16.5%;
  padding-bottom: 2%
}
.main-field__small-text h4{
  font-family: "Parangon_530C", sans-serif;
  font-size: 3.25520833333vw;
  font-weight: bold;
  margin: 0;
  color: black;
}
.main-field__small-text h4 strong{
  color: red;
}
@media screen and (max-width: 991px){
  .main-field__small-logo{
    display: none;
  }
  .main-field .main-field__title h2{
    font-size: 19vw;
    transform: translateX(-6px);
  }
  .main-field__title .main-field__small-text{
    padding-top: 12%;
    width: unset;
    h4{
      font-size: 31px;
    }
  }
}
@media screen and (max-width: 425px){
  .main-field__title .main-field__small-text{
    margin-left: 25px;
  }
  .main-field__title .main-field__small-text h4{
    font-size: 23px;
  }
}
