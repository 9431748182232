.breadcrumb{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  background-color: transparent;
}
.breadcrumb li{
  font-family: "Parangon_530C", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}
.breadcrumb li a{
  font-family: "Parangon_330C", sans-serif;
  font-size: 2rem;
  font-weight: lighter;
}
.breadcrumb li span{
  margin: 0 10px;
}
.breadcrumb li:nth-last-child(1):after{
  content: ''
}
@media screen and (max-width: 991px){
  .breadcrumb{
    justify-content: left;
    padding-left: 14%;
  }
}
@media screen and (max-width: 425px){
  .breadcrumb{
    padding-left: 25px;
  }
}
