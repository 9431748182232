.menu{
  width: 100vw;
  height: 0vh;
  position: fixed;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #FCF9FA;
  z-index: 100;
  transition: all .5s linear;
  .menu__wrap{
    height: 80vh;
  }
}
.menu--active{
  height: 100%
}
.menu h2{
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Parangon_530C", sans-serif;
  font-size: 17.5vw;
  margin: 8% 0 0 7%;
}
.menu nav{
  position: relative;
  bottom: 14vw;
  left: 57vw;
}
.menu nav ul li{
  margin-bottom: 10px;
  list-style-type: none;
}
.menu nav ul li a{
  text-transform: uppercase;
  font-family: "Parangon_530C", sans-serif;
  font-size: 4.45416666667vw;
}
.menu nav ul li a:hover{
  color: red;
}
.menu__small-logo{
  display: block;
  position: absolute;
  top: 50px;
  left: 2.8125vw;
  height: 9.11458333333vw;
  z-index: 3;
}
.menu__small-logo--mobile {
  top: 0;
  margin: 25px auto 0 auto;
  height: auto;
  width: 20%;
  position: relative;
}

@media screen and (max-width: 1366px){
  .menu nav{
    bottom: 18vw;
  }
}

@media screen and (max-width: 991px){
  .menu nav{
    bottom: unset;
    left: unset;
    transform: translateY(-18vw);
    ul{
      padding: 0;
      li{
        text-align: center;
        margin-bottom: 40px;
        a{
          font-size: 5rem;
          line-height: 5rem;
        }
      }
    }
  }
  .menu h2{
    margin: 0;
    font-size: 30.5vw;
    position: static;
    transform: translate(-1.5%, 0);
  }
}
@media screen and (max-width: 425px){
  .menu nav ul li a{
    font-size: 25px;
    line-height: 25px;
  }
}
