.approach-to-work{
    h2{
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        color: white;
        font-family: "Parangon_530C" ,sans-serif;
        font-size: 27.928vw;
        transform: translateX(-28px);
        line-height: 26vw;
        z-index: 1;
    }
}
.approach-to-work__inner{
    position: relative;
    z-index: 2;
    width: 75%;
    margin: 0 auto;
    h6{
        font-family: "Parangon_530C" ,sans-serif;
        font-size: 3.875rem;
        strong{
            font-weight: normal;
            color: red;
        }   
    }
    .text{
        margin-bottom: 25px;
        p{
            margin: 0;
            font-size: 1.5625rem;
            font-family: "Parangon_330C" ,sans-serif;
        }
    }
    ul{
        display: flex;
        margin: 7% 0 8% 0;
        padding: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 450px;
        li{
            list-style-type: none;
            position: relative;
            font-size: 2.0625rem;
            font-family: "Parangon_530C" ,sans-serif;
            width: 24%;
            .drop-block{
                position: absolute;
            }
            p{
                width: 100%;
                margin-bottom: 0;
            }
            &:hover{
                .drop-block{
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 100%;
                }
                p{
                    opacity: 1;
                    transition: opacity 0.2s ease-in-out 0.2s;
                } 
            }
        }
    }
}
.drop-block{
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    min-width: 70%;
    max-height: 1rem;
    overflow: hidden;
    transition: all 0.5s linear;
    p{
        text-align: center;
        color: white;
        font-size: 1.5625rem;
        margin: 0;
        width: unset !important;
        padding: 20px 40px;
        font-family: "Parangon_330C" ,sans-serif;
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }
}
.approach-to-work__buttons{
    justify-content: space-between;
    display: none;
    transform: translateY(-450%);
    .approach-to-work__prev,
    .approach-to-work__next{
        color: red;
    }
}
.title-wrapper{
    display: flex;
    width: 85%;
    height: 220px;
}
@media screen and (max-width: 1162px){
    .approach-to-work__inner ul {
        margin: 7% 0 12% 0;
    }
}
@media screen and (max-width: 1788px){
    .approach-to-work__inner ul{
        height: 550px;
    }
}
@media screen and (max-width: 991px){
    
    .approach-to-work__buttons{
        display: flex;
    }
    .approach-to-work{
        margin-top: 25%;
    }
    .approach-to-work h2{
        position: static;
        height: 19vw;
        font-size: 28.3vw;
        transform: translateX(-16px);
    }
    .approach-to-work__inner ul{
        min-height: unset;
    }
    .approach-to-work__inner ul li p{
        width: 100%;
        text-align: center;
        padding: 30px 20px;
        margin-bottom: 15px;
        background-color: red;
        color: white;
        opacity: 1;
        width: 85%;
        margin: 0 auto;
    }
    .drop-block{
        background-color: transparent;
        max-width: unset;
        min-width: unset;
        max-height: unset;
        min-height: unset;
    }
    
    .approach-to-work__inner ul li .drop-block,
    .approach-to-work__inner ul li:hover .drop-block{
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
        min-height: auto;   
    }
    .drop-block p{
        padding: 0 !important;
        color: black !important;
        background-color: transparent !important;
    }
    .title-wrapper{
        background-color: red;
        margin: 0 auto;
        align-items: center;
    }
}


@media screen and (max-width: 936px){
    .title-wrapper{
        height: 170px;
    } 
    .approach-to-work__buttons{
        transform: translateY(-400%);
   }
 }
 @media screen and (max-width: 600px){
    .approach-to-work__buttons{
        transform: translateY(-330%);
   }
 }
@media screen and (max-width: 425px){
    .approach-to-work{
        margin-top: 35%;
    }

    .approach-to-work h2{
        transform: translateX(-8px);
    }
    .approach-to-work__inner{
        width: unset;
        margin: 0 25px;
    }
    .approach-to-work__inner h6{
        font-size: 25px;
    }
    .approach-to-work__inner ul{
        margin: 7% 0 0 0;
    }
    .approach-to-work__inner .text p{
        font-size: 16px;
    }
    .approach-to-work__inner ul li p{
        font-size: 25px;
        
    }
    .drop-block{
        margin-top: 10px;
    }
    .drop-block p{
        font-size: 16px !important;
    }
    .approach-to-work__buttons{
        transform: translateY(-250%);  
    }
}
@media screen and (max-width: 375px){
    .approach-to-work{
        margin-top: 50%;
    } 
    .title-wrapper{
        height: 220px;
    }

}