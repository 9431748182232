footer{
    background-color: #1B1B1B;
}
.custom-footer .footer__inner .contacts{
    margin-left: auto;
}
.footer__inner{
    width:75%;
    margin: 0 auto;
    padding: 3.5% 0 6% 0;
    display: flex;
    justify-content: flex-end;
}

.input-text{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    input{
        width: 48%;
        min-height: 52px;
        padding-bottom: 0;
    }
}
footer .contacts{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a{
        color: white;
        text-align: right;
        margin-top: 10%;
        font-family: "Parangon_530C", sans-serif;
        font-size: 2.0625rem;

    }
}
.social-icons__wrapper{
    display: flex;
    margin-top: 5%;
    justify-content: flex-end;
    .social-icon{
        width: 15%;
        margin-right: 30px;
        
        img{
            width: 100%;
        }
    }
    .social-icon:last-child{
        margin-right: 0;
    }
}
@media screen and (max-width: 991px){
    .footer__inner{
        flex-direction: column;
    }
    .form__wrapper{
        width: 100%;
    }
    .input-text{
        flex-direction: column;
        margin-bottom: 0;
    } 
    .input-text input{
        width: 100%;
        margin-bottom: 35px;
    }
    .social-icons__wrapper{
        justify-content: center;
        margin-bottom: 35px;
    }
    footer .contacts a{
        text-align: center;
        margin-bottom: 35px;
    }
}
@media screen and (max-width: 425px){
    .form__wrapper p{
        font-size: 25px;
    }
    .social-icons__wrapper .social-icon{
        margin-right: 12px;
    }
    footer .contacts a{
        font-size: 16px;
        margin-bottom: 16px;
    }
}
@media screen and (max-width: 320px){
    .approach-to-work__inner ul li p{
        padding: 20px 10px;
        width: 75%;
    }
}