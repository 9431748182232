.information,
.information_active{
  height: 100vh;
  width: 100%;
  transition: left 1s linear;
  overflow-y: scroll;
  position: fixed;
  left: -100%;
  background-color: #FCF9FA;
  z-index: 99;
}

.information img{
  z-index: 4;
}
.information_active{
  left: 0%;
}
.information_active h2,
.information h2{
  height: 100vh;
  position: absolute;
  height: 0;
  width: 100%;
  text-transform: uppercase;
  color: white;
  font-family: "Parangon_530C", sans-serif;
  font-size: 17.4479vw;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 0;
  z-index: 2;
}
.information_active p,
.information p{
  font-family: "Parangon_330C",sans-serif;
  font-size: 1.5625vw;
}

.information__text{
  position: relative;
  z-index: 3;
  padding-top: 5%;
  width: 100%;
  padding-left: 17vw;
}
.information__text a{
  font-size: 2rem;
}
.information form{
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
}
.information form input,
.information label{
  text-align: center;
  border: none;
  border-bottom: 3px solid black;
  color: #A7A6A7;
  background-color: #FCF9FA;
  font-family: "Parangon_530C",sans-serif;
  font-size: 1.5rem;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 1%;
}
.information label{
  cursor: pointer;
  color: #717171;
}
.information form input[type="file"]{
  display: none;
}
.information form input[type="submit"]{
  text-transform: uppercase;
  background-color: red;
  border: none;
  outline: none;
  color: white;
  padding: 5px 25px;
  width: unset;
  margin: 0 auto;
}
.information form input:focus{
  outline: none;
}
 @media screen and (max-width: 991px){
  .information h2, .information_active h2{
    font-size: 24.6vw;
    transform: translateX(-5px);
  }
  .information__text{
    padding-left: 12vw;
  }
  .information form input{
    width: 50%;
  }
  .information p, .information_active p{
    font-size: 25px;
  }
  .information form input[type=submit]{
    width: 157px;
  }
 }
 @media screen and (max-width: 768px){
  .information h2, .information_active h2{
    margin-top: 10%;
  }
  .information__text{
    padding-top: 8%;
  }
  .information p, .information_active p{
    font-size: 20px;
  }
 }
 @media screen and (max-width: 425px){
  .information__text{
    padding-top: 25px;
  }
  .information h2, .information_active h2{
    margin-top: 65px;
  }
  .information form input,
  .information label{
    width: 90%;
    margin: 0 25px 27px 25px;
    font-size: 14px;
  }
  .information form input[type=submit]{
    margin-top: 15px;
  }
  .information p, .information_active p{
    font-size: 16px;
  }
 }
 @media screen and (max-width: 320px){
  .information form input{
    margin: 0 25px 15px 25px;
  }
 }