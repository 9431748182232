body.case .breadcrumb li{
    display:none;
}
.main-field_big_img {
    margin: 0 auto 15px auto !important;
}
body.case .breadcrumb li:nth-last-child(1){
    display:block;
}
.case-page {
    width: 99vw;
}
.case-page img{
    max-width: 100%;
    width: auto;
    height: auto;
}
.case-main-field > div {
    width: 75%;
    margin: 0 auto 75px auto;
}
.case-page .main-field__title h2{
    font-size: 21.5vw;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    max-width: 75%;
    z-index: -1;
    position: absolute;
}

.case_field_content {
    margin-bottom: 100px;
}
.case_field_content h3 {
    font-family: Parangon_530C,sans-serif;
    font-size: 3.25520833333vw;
    font-weight: 700;
    margin: 0;
    color: #000;
    margin-bottom: 90px;
}
.case_field_content h3 span{
    color: red;
}
.case_field_content p{
    font-size: 1.5625rem;
    font-family: Parangon_330C,sans-serif;
    margin-bottom: 10px;
    text-align: justify;
}
.case_field_content_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 47%;
}
.case_field_content_left > img:nth-child(1) {
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-bottom: 40px;
}
.case_field_content_right > img:nth-last-child(1) {
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-top: 40px;
}
.case_field_content_left > img:nth-last-child(1) {
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-top: 40px;
}
.case_field_content_right > img:nth-child(1) {
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-bottom: 40px;
}
.case_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.case_field_content_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 47%;
}
.social_element {
    text-align: center;
    display: inline-block;
}
.social_element .social-icon{
    display: inline-block;
    width: 44%;
}
.social_element .social-icon a span{
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    font-size: 29px;
    font-family: Parangon_330C,sans-serif;
    padding: 0px;
}
.case_field_gallery {
    display: inline-block;
    vertical-align: bottom;
    text-align: center;
    margin: -150px 0px;
}
.case_field_gallery img:nth-child(3) {
    max-height: 435px;
}
.case_field_gallery img:nth-child(4) {
    vertical-align: top;
}
.case_field_gallery img:nth-child(5) {
    max-height: 435px;
    vertical-align: top;
}
.case_field_gallery img {
    max-height: 235px;
    vertical-align: bottom;
    margin: 5px;
    display: inline-block;
}
.case_field_slider {
    margin-top: 40px;
    margin-bottom: 70px;
    box-shadow: 0px 0px 50px #bcbcbc;
}
.case_field_slider .slick-prev{
    left: 25%;
}
.case_field_slider .slick-arrow{
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
    font-size: 4em;
    color: red;
    cursor: pointer;
    background: none;
    border: none;
    width: 26px;
    height: 53px;
    position: absolute;
    overflow: hidden;
    bottom: -55px;
}
.case_field_slider .slick-prev:before{
    content: "\f0d9";
}
.case_field_slider .slick-next{
    right: 25%;
}
.case_field_slider .slick-next:before{
    content: "\f0da";
}
.case_field_slider .slick-dots {
    position: absolute;
    padding: 0px;
    margin: 0px;
    text-align: center;
    width: 30%;
    list-style: none;
    margin: 25px 35% 0px;
}
.case_field_slider .slick-dots li{
    display: inline-block;
    margin: 0px 3px;
}
.case_field_slider .slick-dots li.slick-active button{
    background: #000;
}
.case_field_slider .slick-dots li button{
    cursor: pointer;
    border: none;
    padding: 4px;
    font-size: 0px;
    border-radius: 50px;
    background: #b4b4b4;
}
.case_field_stat {
    width: 100%;
    text-align: center;
    display: inline-block;
}
.case_field_stat .case_field_stat_num{
    display: inline-block;
    width: 49%;
    margin-bottom: 80px;
}
.case_field_stat .case_field_stat_num span.stat_num{
    display: inline-block;
    width: 100%;
    color: #ff1500;
    font-family: Parangon_530C,sans-serif;
    font-size: 100px;
    font-weight: 700;
    line-height: 1.3;
}
.case_field_stat .case_field_stat_num span.stat_text{
    font-size: 1.5625rem;
    font-family: Parangon_330C,sans-serif;
    margin-bottom: 10px;
    text-align: center;
}
.case_field_stat_detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 70px;	
}
.case_field_stat_detail_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 65%;
}
.case_field_stat_detail_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 30%;
}
.text_center p{
    text-align: center;
}
.case_field_stat_detail img{
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-bottom: 40px;	
}
.case_field_stat_detail img:nth-child(3){
    margin-top: 30px;	
}
.case_field_rand_gallery {
    margin-bottom: 100px;
}
.case_field_rand_gallery img {
    display:none;
}
.case_field_rand_gallery img:nth-child(1) {
    display:block;
}
.footer_contact_form {
    width: 65%;
}
.footer_contact_form #contact-form{
    max-width: 75%;
}
.footer_contact_form #contact-form h3{
    color: #ff2323;
    text-align: left;
    margin-top: 17px;
    font-family: Parangon_530C,sans-serif;
    font-size: 2.0625rem;
    margin-bottom: 25px;	
}
.footer_contact_form #contact-form input:nth-last-child(1) {
    text-transform: uppercase;
    background-color: red;
    border: none;
    outline: 0;
    color: #fff;
    padding: 5px 25px;
    width: unset;
    margin: 0 auto;
    cursor: pointer;
}
.footer_contact_form #contact-form input{
    display: inline-block;
    width: 47%;
    margin-right: 2%;
    color: #333;
    text-align: left;
    font-family: Parangon_530C,sans-serif;
    font-size: 15px;
    padding: 5px;
    border-radius: 0px;
    border: none;
    margin-bottom: 20px;
}
.footer_contact_form #contact-form textarea{
    display: inline-block;
    width: 96.5%;
    margin-right: 0%;
    color: #333;
    text-align: left;
    font-family: Parangon_530C,sans-serif;
    font-size: 15px;
    padding: 5px;
    border-radius: 0px;
    border: none;
    margin-bottom: 20px;
}
.footer__inner .contacts {
    width: 35%;
}
body.case .footer__inner {
    width: 75%;
    margin: 0 auto;
    padding: 3.5% 0 6% 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.hidepc {
    display:none;
}
.showpc {
    display:block;
}
.case-page .case_field_content_left > ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
    padding-left: 25px;	
}
.case-page .case_field_content_left > ul li{
    font-size: 1.5625rem;
    font-family: Parangon_330C,sans-serif;
    margin-bottom: 10px;
    text-align: justify;
    position: relative;
}
.case-page .case_field_content_left > ul li:before{
    content: '';
    position: absolute;
    background: #ff2323;
    width: 7px;
    height: 7px;
    border-radius: 50px;
    left: -14px;
    top: 9px;	
}
body.case-cat .case-page {
    margin-top: -60px;
}
.case-category {
    margin-top: -50px !important;	
}
.case-category h3.case-category-title{
    font-family: Parangon_530C,sans-serif;
    font-size: 3.25520833333vw;
    font-weight: 700;
    margin: 0;
    color: #000;
    margin-bottom: 5px;
}
.case-category h3.case-category-title span{
    color: red;	
}
.case-category-desc {
    margin-bottom: 75px;
}
.case-category-desc p{
    font-family: Parangon_530C,sans-serif;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    color: #000;
    margin-bottom: 0px;
    line-height: 1.2;
}
.case-category-desc p:nth-last-child(1){
    margin-top: 35px;
    max-width: 85%;	
}
.case-list-items {
    display: inline-block;
    width: 100%;
}
.case-list-items .case-list{
    padding: 0px;
    margin: 0px;
    list-style: none;
    text-align: left;
    display: inline-block;	
    width: 100%;
}
.case-list-items .case-item:nth-child(2n) {
    margin-right: 0px;
}
.case-list-items .case-item{
    display: inline-block;
    width: 48%;
    margin-right: 2%;
    margin-bottom: 30px;
    position: relative;
}
.case-list-items .case-item-link:hover{
    transition: 0.2s;
    opacity: 0.9;
}
.case-list-items .case-item-name{
    font-size: 19px;
    font-family: Parangon_330C,sans-serif;
    margin-top: 15px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
@media screen and (min-width:980px){
body.case-cat  header {
    min-height: 130px;
}
.hidepc {
    display: none !important;
}
.case_field_gallery img:nth-child(6) {
    max-height: 435px;
    vertical-align: top;
}
}
@media screen and (max-width:500px){
    .case_field_gallery div:nth-child(1) img {
    max-height: 127px;
}
    .case_field_gallery div:nth-child(3) img {
max-height: 198px;
}
    div.case_field_stat .case_field_stat_num span.stat_num {
    font-size: 75px;
    line-height: 1.5;
}
.case_field_gallery div {
display: inline-flex;
position: relative;
max-width: 320px;
}
.case-main-field .mobile-margin-top{
    margin-top: 50px;
}
.case_field_gallery img {
    // max-height: 400px;
    vertical-align: bottom;
    margin: 5px 5px 0px;
    max-width: 100%;
    display: inline-block;
}
}
@media screen and (max-width:980px){
.case_field_slider .slick-arrow{
   font-size: 0;
   &:before{
       font-size: 4rem;
   }
}
.case-list-items .case-item-name {
    font-size: 15px;
}
.case-category h3.case-category-title {
    display: none;
}
.case-category-desc {
    display: none;
}
    .case-cat header {
    min-height: 65px;
}
body.case-cat .case-page {
    margin-top: 80px;
}
.case-category {
    margin-top: 0px !important;
    width: 94% !important;
    margin: 0px 3% !important;
}
.case-category h3.case-category-title {
    font-size: 29px;
}
.case-category-desc {
    margin-bottom: 50px;
}
.case-category-desc p:nth-child(1) {
    font-size: 28px;
    font-family: Parangon_530C,sans-serif;
    margin-bottom: 20px;
}
.case-category-desc p {
    font-family: Parangon_330C,sans-serif;
    font-size: 23px;
    font-weight: 100;
}
.case-category-desc p:nth-last-child(1) {
    margin-top: 25px;
    max-width: 100%;
}
.case-list-items .case-item {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 40px;
}
body.case-cat .case-page .about-us__main-field h2 {
    max-width: 100%;	
}
body.case-cat .case-page .about-us__main-field{
    margin-top: -100px;
    margin-bottom: 70px;
}
.showpc {
    display: none !important;
}
.case_field_gallery {
    margin: 15px 0px;
    width: 100%;
}
body.case .breadcrumb li:nth-last-child(1) {
    display: block;
    width: 90%;
    text-align: center;
    font-size: 18px;
}
body.case .breadcrumb {
    padding-left: 5%;
}
.case-main-field > div {
    width: 100%;
    margin: 0 auto 2% auto;
}
.case_field_content {
    margin-bottom: 70px;
}
.case_field_content h3 {
    font-size: 33px;
    width: 94%;
    margin: 10px 3% 50px;
}
.case_field_gallery {
    margin: 15px 0px;
}
.case_field_content_left {
    display: inline-block;
    width: 100%;
}
.case_field_content_right {
    display: inline-block;
    width: 100%;
    margin-top: 35px;
}
.case_wrapper {
    width: 94%;
    margin: 0px 3%;
    display: inline-block;
}
.case_field_content_left > img:nth-child(1) {
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-bottom: 40px;
}
.case_field_slider {
    box-shadow: 0px 0px 50px #bcbcbc;
    width: 100%;
    margin: 40px 0% 70px;
}
.case_field_stat {
    width: 100%;
    text-align: center;
    display: inline-block;
}
.case_field_stat .case_field_stat_num {
    display: inline-block;
    width: 100%;
    margin-bottom: 90px;
}
.case_field_stat .case_field_stat_num span.stat_text {
    font-weight: bold;
}
.case_field_stat .case_field_stat_num span.stat_num {
    font-size: 145px;
    line-height: 1;
}
.case_field_stat_detail {
    display: inline-block;
    width: 94%;
    margin: 0px 3% 0% 3%;
}
.case_field_stat_detail_left {
    display: inline-block;
    width: 100%;
}
.case_field_stat_detail img {
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-bottom: 40px;
}
.text_center p {
    text-align: left;
}
.case_field_stat_detail img:nth-child(3) {
    margin-top: 30px;
}
.case_field_stat_detail_right {
    display: inline-block;
    width: 100%;
    margin-top: 40px;
}
.case_field_stat_detail_full {
    width: 94%;
    margin: 30px 3% 10px;
}
.case_field_rand_gallery {
    margin-bottom: 100px;
}
.case-main-field > div > .case_field_content:nth-last-child(1) h3 {
    text-align: center;
}
.case_field_content_left > img:nth-last-child(1) {
    margin-top: 40px;
    box-shadow: 0px 0px 50px #bcbcbc;
    margin-bottom: 25px;
}
.case_field_content_right {
    width: 100%;
    margin-top: 35px;
}
body.case .footer__inner {
    width: 94%;
    margin: 0 auto;
    padding: 3.5% 0 6% 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
body.case .footer_contact_form {
    width: 100%;
}
body.case .footer_contact_form #contact-form {
    max-width: 100%;
}
body.case .footer_contact_form #contact-form h3 {
    color: #ff2323;
    text-align: left;
    margin-top: 17px;
    font-family: Parangon_530C,sans-serif;
    font-size: 3.063rem;
    margin-bottom: 25px;
}
body.case .footer_contact_form #contact-form textarea {
    display: inline-block;
    width: 100%;
    margin-right: 0%;
    color: #333;
    text-align: left;
    font-family: Parangon_530C,sans-serif;
    font-size: 17px;
    padding: 10px;
    border-radius: 0px;
    border: none;
    margin-bottom: 20px;
}
body.case .footer__inner .contacts {
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
}
body.case .social-icons__wrapper {
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    margin-bottom: 20px;
    margin-top: 70px;
}
body.case .social-icons__wrapper .social-icon {
    width: auto;
    margin-right: 20px;
}
body.case footer .contacts a {
    text-align: right;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 20px;
}
body.case .social-icons__wrapper .social-icon img {
    width: 50px;
}
body.case .main-field__small-logo {
    display: block !important;
    top: 20px;
}
.hidepc {
    display:block;
}
.showpc {
    display:none;
}
}