#scene,
#scene2,
#scene3,
#scene4,
#scene5{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  animation: opacity 2s ease-in-out;
}
#scene{
  align-items: flex-start;
  justify-content: center;
}
#scene2{
  align-items: center;
}
#scene3{
  justify-content: flex-end;
  align-items: center;
}
#scene4{
  justify-content: center;
  align-items: flex-end;
}
#scene5{
  justify-content: center;
  align-items: center;
  img{
    width: 80%;
  }
}
.scene__wrapper,
.main-logo{
  pointer-events: auto;
}
.scene__wrapper--top,
.scene__wrapper--bottom{
  width: 100vw;
  display: flex;
  justify-content: center;
}
.scene__wrapper--left{
  width: 100vh;
}
.scene__link{
  font-family: "Parangon_530C", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  display: block;
  position: absolute;
}
.scene__link--top-position{
  transform: translateY(-50%)
}
.scene__link--left-position{
  transform: translateX(-50%) rotate(-90deg);
}
.scene__link--right-position{
  transform: rotate(90deg) translateY(-190%);
  transform-origin: center;
  right: 0vw;
}
.scene__link--bottom-position{
  transform: translateY(70%);
  bottom: 0;
}
@keyframes opacity{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }

}

@media screen and (max-width: 991px){
  .first-page{
    display: flex;
    flex-direction: column;
  }
  #scene, #scene2, #scene3, #scene4, #scene5 {
    position: unset;
    justify-content: center;
  }
  .scene__link--top-position{
    transform: translateY(0);
  }
  .scene__link--left-position{
    transform: translateX(0) rotate(0); 
  }
  .scene__link--right-position{
    transform: translateX(0) rotate(0); 
  }
  .scene__link--bottom-position{
    transform: translateY(0);
  }
  .scene__wrapper--left{
    width: unset;
  }
  .scene__link{
    position: unset;
  }
  #scene5{
    margin: 12% auto;
  }
}
@media screen and (max-width: 991px){
  #scene5{
    height: unset;
    margin-bottom: 50px;
  }
.main-page__scene-wrapper{
  position: absolute;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}
  #scene, #scene2, #scene3, #scene4{
    height: unset;
  }
}
@media screen and (max-width: 425px){
  #scene5{

    img{
      width: 161px;
    }
  }
  #scene, #scene2, #scene3, #scene4, #scene5 {
    a{
      font-size:  25px;
      margin-bottom: 37px;
    }
  }
}

